import request from "../requset";
import getUrlQuery from "../utils/getUrlQuery"

interface getSalesPriceDataType {
    fromk:string
    page_path?:string
}
export const $getSalesPrice = (data:getSalesPriceDataType) => { //价格接口，版权信息接口
    return request({
        url:`https://playlet.youhuixiang.cn/api/v1/getSalesPrice`,///getSalesPrice
        method:'get',
        params:data
    })
}

interface addOrderAIDrawTyle {
    is_measure:number |string
    fromk: string
    pageUrl: string
    openid?:string
}
export const $addOrderAIDraw = (params:addOrderAIDrawTyle) => { //首页提交用户信息
    return request({
        url:'https://playlet.youhuixiang.cn/api/v1/addOrderAIDraw',
        method:'post',
        data:params
    })
}

interface orderGetOrderRollCountType {
    order_id:string
}
export const $orderGetOrderRollCount = (params:orderGetOrderRollCountType) => {//查询抽奖次数 
    return request({
        url:`https://playlet.youhuixiang.cn/api/v1/order/getOrderRollCount`,
        method:'post',
        data:params
    })
}

interface orderAddOrderRollCountType {
    order_id:string
}
export const $orderAddOrderRollCount = (params:orderAddOrderRollCountType) => {//使用抽奖次数 
    return request({
        url:`https://playlet.youhuixiang.cn/api/v1/order/addOrderRollCount`,
        method:'post',
        data:params
    })
}
export const $setOrderReceived = (params:orderAddOrderRollCountType) => {//使用抽奖次数 
    return request({
        url:`https://playlet.youhuixiang.cn/api/v1/setOrderReceived`,
        method:'post',
        data:params
    })
}


export const $wechatqyGetKfUrl = (data:string) => {//获取公众号地址
    return request({
        url:`https://playlet.youhuixiang.cn/api/v1/wechatqy/getKfUrl/${data}`,
        method:'get',
    })
}

export const $order = (data:string) => {//获取用户信息
    return request({
        url:`https://playlet.youhuixiang.cn/api/v1/order/${data}`,
        method:'get'
    })
}

interface aidrawGetOrderAllTagsDataTyle {
    order_id:string
}
export const $aidrawGetOrderAllTagsData = (params:aidrawGetOrderAllTagsDataTyle) => {//获取结果页初始化图片信息
    return request({
        url:`https://playlet.youhuixiang.cn/api/v1/aidraw/getOrderAllTagsData`,
        method:'post',
        data:params
    })
}

export const $aidrawGetOrderAllTagsPicData = (params:aidrawGetOrderAllTagsDataTyle) => {//获取结果页初始化图片信息2
    return request({
        url:`https://playlet.youhuixiang.cn/api/v1/aidraw/getOrderAllTagsPicData`,
        method:'post',
        data:params
    })
}

interface aidrawGetOrderPicDataTyle{
    order_id:string
    pic_tag:number
    pic_index:number
}
export const $aidrawGetOrderPicData = (params:aidrawGetOrderPicDataTyle) => {//结果页图片信息查询
    return request({
        url:`https://playlet.youhuixiang.cn/api/v1/aidraw/getOrderPicData`,
        method:'post',
        data:params
    })
}

interface aidrawSetOrderTagsType{
    order_id:string
    pic_tags:number[]
}
export const $aidrawSetOrderTags = (params:aidrawSetOrderTagsType) => {//6个标签设置
    return request({
        url:`https://playlet.youhuixiang.cn/api/v1/aidraw/setOrderTags`,
        method:'post',
        data:params
    })
}


interface orderConfirmType {
    order_id:string
    paytype:string
    discount_id?:number
    discount_type?:string
}
export const $orderConfirm = (params:orderConfirmType) => { //确认支付接口
    return request({
        url:'https://playlet.youhuixiang.cn/api/v1/order/confirm',
        method:'post',
        data:params
    })
}

export const $paymentWechathyPayment = (data:string,data2:string) => {//微信支付接口参数
    return request({
        url:`https://playlet.youhuixiang.cn/api/v1/payment/wechathy/${data}/${data2}/wechat`,
        method:'get',
    })
}

interface paymentQueryStatusType {
    order_id:string
}
export const $paymentQueryStatus = (params:paymentQueryStatusType) => {//判断支付状态
    return request({
        url:`https://playlet.youhuixiang.cn/api/v1/payment/query_status`,
        method:'post',
        data:params
    })
}

interface paymentQueryStatusSyncType {
    order_id:string
}
export const $paymentQueryStatusSync = (params:paymentQueryStatusSyncType) => {//判断支付状态
    return request({
        url:`https://playlet.youhuixiang.cn/api/v1/payment/query_status_sync`,
        method:'post',
        data:params
    })
}

/**
 * https://lily.origv.com/api/v1/getPageAdConfig/page_ai_draw?from=xx1&order_id=eOA
 */

interface getPageAdConfigPageAiDrawType {
    from?:string,
    order_id?:string 
}

export const $getPageAdConfigPageAiDraw = (data:getPageAdConfigPageAiDrawType) => {//商城广告接口
    return request({
        url:`https://playlet.youhuixiang.cn/api/v1/getPageAdConfig/page_ai_draw`,
        method:'get',
        params:data
    })
}
export const $getPageAdConfigPageMDjShop= (data:getPageAdConfigPageAiDrawType) => {//商城广告接口
    return request({
        url:`https://playlet.youhuixiang.cn/api/v1/getPageAdConfig/page_m_dj_shop`,
        method:'get',
        params:data
    })
}
export const $getPageAdConfigPageMDjShop2= (data:getPageAdConfigPageAiDrawType) => {//商城广告接口2
    return request({
        url:`https://playlet.youhuixiang.cn/api/v1/getPageAdConfig/page_m_dj_shop_2`,
        method:'get',
        params:data
    })
}
 interface orderBindIphoneType {
    order_id:string
    bind_iphone:number | string
 }
export const $orderBindIphone = (params:orderBindIphoneType) => {//提交手机号码
    return request({
        url:'https://playlet.youhuixiang.cn/api/v1/order/bindIphone',
        method:'post',
        data:params
    })
}
export const $getPageAdConfigPageMBonus = (data:getPageAdConfigPageAiDrawType) => {//微信支付接口参数
    return request({
        url:`https://playlet.youhuixiang.cn/api/v1/getPageAdConfig/page_m_bonus`,
        method:'get',
        params:data
    })
}

interface aidrawDownloadPicType {
    order_id:string
    pic_tag:number | string
    pic_index:number
}

export const $aidrawDownloadPic = (params:aidrawDownloadPicType) => {//下载图片
    return request({
        url:`https://playlet.youhuixiang.cn/api/v1/aidraw/downloadPic`,
        method:'post',
        data:params
    })
}

export const $paymentDorefund = (params:{out_trade_no:string},user_id:string) => {//退款接口 
    return request({
        url:`https://playlet.youhuixiang.cn/api/v1/payment/dorefund/${user_id}`,
        method:'post',
        data:params
    })
}

interface aidrawSetOrderAddressType {
    "order_id": string
    "address_name":  string
    "address_phone": string
    "address_detail":  string

}
export const $aidrawSetOrderAddress = (params:aidrawSetOrderAddressType) => {//抽中奖的信息提交接口 
    return request({
        url:`https://playlet.youhuixiang.cn/api/v1/aidraw/setOrderAddress`,
        method:'post',
        data:params
    })
}

export const $stat = (type:string) => {
    return request({
        url:'https://playlet.youhuixiang.cn/api/v1/stat/cli',
        method:'post',
        data:{
            type,
            platform:1,
            fromk:getUrlQuery(window.location.href,'from')
        }
    })
}

interface showAnswerType {
    order_id:string
}
export const $showAnswer = (params:showAnswerType) => {//判断是否近企微
    return request({
        url:`https://playlet.youhuixiang.cn/api/v1/showAnswer`,
        method:'post',
        data:params
    })
}
interface paymentWechatPayNewPageHostType {
    order_id:string
}
export const $paymentWechatPayNewPageHost = (params:paymentWechatPayNewPageHostType) => {//判断是否近企微
    return request({
        url:`https://playlet.youhuixiang.cn/api/v1/payment/wechat_pay_new_page_host`,
        method:'post',
        data:params
    })
}

interface orderGetOrderFeedbackInfoType {
    order_id:string
}
export const $orderGetOrderFeedbackInfo = (params:orderGetOrderFeedbackInfoType) => {//判断是否近企微
    return request({
        url:`https://playlet.youhuixiang.cn/api/v1/order/getOrderFeedbackInfo`,
        method:'post',
        data:params
    })
}

interface orderSetOrderFeedbackInfoType {
    order_id:string
    feedback_type:string
    pay_scenario:string
    pay_scenario_name:string
    feedback_content:string
    feedback_tel:string

}
export const $orderSetOrderFeedbackInfo = (params:orderSetOrderFeedbackInfoType) => {//判断是否近企微
    return request({
        url:`https://playlet.youhuixiang.cn/api/v1/order/setOrderFeedbackInfo`,
        method:'post',
        data:params
    })
}

interface orderGetInfoByOutTradeNoType {
    out_trade_no:string
}
export const $orderGetInfoByOutTradeNo = (params:orderGetInfoByOutTradeNoType) => {//判断是否近企微
    return request({
        url:`https://playlet.youhuixiang.cn/api/v1/order/getInfoByOutTradeNo`,
        method:'post',
        data:params
    })
}

export const $getXcxUrl = (data1:string,data2:string) => {//获取小程序链接
    return request({
        url:`https://playlet.youhuixiang.cn/api/v1/getXcxUrl/${data1}/${data2}`,
        method:'get'
    })
}








// interface addOrderParamsType {
//     // [addOrderParams:string]:any
//     order_type:number | string
//     birth_state:number
//     pub_birth_date:string
//     name:string
//     xname:string
//     fromk:string | null
//     yl:number
//     pageUrl:string
//     sex:string | number
//     openid:string| null
//     mch_id:string| null
//     kf:string| null
//     parent_order_id:string| null
// }
// export const $addOrder = (params:addOrderParamsType) => { //首页提交用户信息
//     return request({
//         url:'/api/v1/addOrder',
//         method:'post',
//         data:params
//     })
// }

// interface checkWordDataType {
//     word:string
//     from:string | null
// }
// export const $checkWord = (data:checkWordDataType) => {//姓名检测(是否是国家领导人名字)
//     return request({
//         url:'/api/v1/checkWord',
//         method:'get',
//         params: data,
//     })
// }
// export const $constPayBtnSwitch = () => {//控制隐藏或显示微信支付或支付宝支付的按钮
//     return request({
//         url:'/api/v1/const/pay_btn_switch',
//         method:'get'
//     })
// }


// type orderDataType = string

// export const $order = (data:orderDataType) => {//控制隐藏或显示微信支付或支付宝支付的按钮
//     return request({
//         url:`/api/v1/order/${data}`,
//         method:'get'
//     })
// }
